<template>
  <div
    class="min-height-300 position-absolute w-100"
    :class="`${this.$store.state.darkMode ? 'bg-transparent' : 'bg-success'}`"
  />
  <aside
    class="my-3 overflow-auto border-0 sidenav navbar navbar-vertical navbar-expand-xs border-radius-xl"
    :class="`${
      'fixed-start ms-3'
    } ${
      this.$store.state.layout === 'landing'
        ? 'bg-transparent shadow-none'
        : ($store.state.darkMode ? '' : 'bg-white')
    }`"
    id="sidenav-main"
    v-if="!this.$route.params.shareToken && $store.state.showPrimaryNav"
  >
    <div class="sidenav-header">
      <i
        class="top-0 p-3 cursor-pointer fas fa-times text-secondary opacity-5 position-absolute end-0 d-none d-xl-none"
        aria-hidden="true"
        id="iconSidenav"
      ></i>
      <router-link class="m-0 navbar-brand text-left" to="/">
        <img
          :src="
            this.$store.state.darkMode ||
            this.$store.state.sidebarType === 'bg-default'
              ? logoWhite
              : logo
          "
          class="navbar-brand-img h-100"
          alt="main_logo"
        /><br />
        <span class="font-weight-bold me-2">Aderize dashboard</span>
      </router-link>
    </div>
    <hr class="mt-0 horizontal dark" />
    <sidenav-list :cardBg="custom_class" />
  </aside>
  <aside v-if="$store.state.secondaryNavbar === 'reporting'" class="my-3 overflow-x-visible border-0 sidenav navbar navbar-vertical navbar-expand-xs border-radius-xl fixed-start ms-3 
     secondary-navbar"
      :class="`${this.$route.params.shareToken ? 'no-primary-nav' : ''} ${$store.state.darkMode ? '' : 'bg-white'}`" id="sidenav-secondary">
      
    <reporting-sidenav-list :cardBg="custom_class" />
  </aside>
</template>
<script>
import SidenavList from "./SidenavList.vue";
import ReportingSidenavList from "@/views/ad-server/reporting/ReportingSidenavList.vue";
import logo from "@/assets/img/logo.svg";
import logoWhite from "@/assets/img/logo-white.svg";

export default {
  name: "index",
  components: {
    SidenavList,
    ReportingSidenavList
  },
  data() {
    return {
      logo,
      logoWhite
    };
  },
  props: ["custom_class", "layout"]
};
</script>
